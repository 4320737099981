
export default {
	'\\"' : '"',
	"\\'" : "'",
	"\\\\" : "\\",
	"•" : "-",
	"—" : "-",
	"−" : "-",
	"–" : "-",
	"´" : "'",
	"`" : "'",
	"„" : "\"",
	"“" : "\"",
	"″" : "\"",
	"”" : "\"",
	"Ä" : "&Auml;", 
	"ä" : "&auml;", 
	"Ö" : "&Ouml;", 
	"ö" : "&ouml;", 
	"Ü" : "&Uuml;", 
	"ü" : "&uuml;", 
	"ß" : "&szlig;",
	"€" : "&euro;",
	"£" : "&pound;",
	"¥" : "&yen;",
	"µ" : "&micro;",
	"&amp;amp;" : "&amp;"
};