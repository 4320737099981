var prestyle = `
<style type="text/css">
	.pmPreheader { 
		color:transparent; 
		display:none !important; 
		height:0; opacity:0; 
		visibility:hidden; 
		width:0;
	}
</style>`;
function predom(pretext) {
	return `
	<div class="pmPreheader" 
		style="color:transparent; 
		color:#7ab800; 
		display:none !important; 
		font-family:Arial,Helvetica,sans-serif; 
		font-size:10px; 
		height:0; 
		line-height:14px; 
		opacity:0; 
		visibility:hidden; 
		width:0;">\n` + 
		pretext + 
		`\n</div>\n`;
}

export {
	predom,
	prestyle
};